import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CustomDatePicker from '../components/CustomDatePicker'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { BusinessOutlined, Cancel, Description, DescriptionOutlined, HelpOutline, MailOutline, Person, PersonOutline, PhoneOutlined, SubjectOutlined } from "@mui/icons-material"
import { useEffect, useState, useContext } from "react"
import { MaterialReactTable } from 'material-react-table'
import { Autocomplete, Button, FormControl, Grid, IconButton, InputAdornment, ListItemIcon, TextField } from "@mui/material"
import { MenuItem } from '@mui/material'
import { AddCircleOutline, Close, DeleteOutline, Edit, Send } from "@mui/icons-material"
import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import 'react-toastify/dist/ReactToastify.css'
import { AuthContext } from "../App"
import { toast } from "react-toastify"
import { instance } from "../utils/api"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const cl = 'app-page-ticketlist'

const RenewSubscription = ({ isopen, setPopup, row, setTriggerRefresh,domain,data,fetchSubscribeData}) => {
    const [name, setName] = useState("")
    const [full_name, setFull_name] = useState('')
  
    const [country, setCountry] = useState('')
    const [website, setWebsite] = useState('')
    const [reg_number, setRegNumber] = useState('')
    const [contact_email, setContactEmail] = useState('')
    const [contact_number, setContactNumber] = useState('')
    const [emp_id_code, setEmpIdCode] = useState('')
    const [pan_card, setPanCard] = useState('')
    const [reseller, setReseller] = useState(undefined)
    const [sales, setSales] = useState(undefined)
    const [logo, setLogo] = useState('')
    const [logo2, setLogo2] = useState('')
    const [financial_start_month, setFinancialStartMonth] = useState('April')
    const [financial_end_month, setFinancialEndMonth] = useState('March')
    const [start_date, setStartDate] = useState('')
    const [expiry_date, setExpiryDate] = useState('')
    const [isStartPickerOpen, setIsStartPickerOpen] = useState(false)
    const [isExpiryPickerOpen, setIsExpiryPickerOpen] = useState(false)
    const [package_id, setPackageId] = useState('')
    const [address, setAddress] = useState('')
    const [is_active, setIsActive] = useState('active')
    const [apiData, setApiData] = useState([])
    const [serialNumbers, setSerialNumbers] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [addconsumerPopup, setAddconsumerPopup] = useState(false)
    const [selectedConsumer, setSelectedConsumer] = useState(null)
    const [countryList, setCountryList] = useState([])
    const [autocompleteOpen, setAutocompleteOpen] = useState(false)
    const [autocompleteOpen1, setAutocompleteOpen1] = useState(false)
    const [autocompleteOpen2, setAutocompleteOpen2] = useState(false)
    const [resellerList, setResellerList] = useState([])
    const [salesList, setSalesList] = useState([])
    const [transaction_id ,setTransaction_id] = useState("")
    const [description,setDescription] = useState('')
    const Token = localStorage.getItem("Token")
    const [fieldErrors, setFieldErrors] = useState({
        name: false,
        full_name: false,
        domain: false,
        website: false,
        country: false,
        reg_number: false,
        contact_email: false,
        contact_number: false,
        emp_id_code: false,
        logo: false,
        logo2: false,
        financial_start_month: false,
        financial_end_month: false,
        start_date: false,
        expairy_date: false,
        package_id: false,
        address: false,
        is_active: false,
        pan_card: false,
        reseller: false,
        sales: false,
        transaction_id: false,
    })
    const [packageList, setPackageList] = useState([])
    const [datesAutofilled, setDatesAutofilled] = useState(false)

    const { state, actions } = useContext(AuthContext)

    const userdata = state.userdata

    const token = localStorage.getItem("Token")

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
    ]

    const fetchPackages = async () => {
        try {
            const response = await instance.get("/Superadmin/Package/list", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            const packages = response.data.results
            setPackageList(packages)
        } catch (error) {
            console.error("Error:", error)
        }
    }

    const fetchCountry = async () => {
        try {
            const response = await instance.get("/Superadmin/Country/list", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            setCountryList(response.data.results)
        } catch (error) {
            console.error("Error:", error)
        }
    }

    const fetchResellers = async () => {
        try {
            const response = await instance.get("/Superadmin/Author/list?role=Reseller", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            setResellerList(response.data.results)
        } catch (error) {
            console.error("Error:", error)
        }
    }

    const fetchSales = async () => {
        try {
            const response = await instance.get("/Superadmin/Author/list?role=Sales", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            setSalesList(response.data.results)
        } catch (error) {
            console.error("Error:", error)
        }
    }

    // const formattedDomain = domain.includes('.vibhohcm.com') ? domain : `${domain}.vibhohcm.com`

    const formData = {
        name: name,
        full_name: full_name,
        transaction_id:transaction_id,
        description:description,
  
        website: website,
        country: country,
        reg_number: reg_number,
        contact_email: contact_email,
        contact_number: contact_number,
        emp_id_code: emp_id_code,
        pan: pan_card,
        reseller: reseller,
        sales_person: sales,
        logo: logo ? logo.join(',') : "",
        logo2: logo2 ? logo2.join(',') : "",
        financial_start_month: months.indexOf(financial_start_month) + 1,
        financial_end_month: months.indexOf(financial_end_month) + 1,
        start_date: new Date(start_date).getFullYear() + "-" + (new Date(start_date).getMonth() + 1) + "-" + new Date(start_date).getDate(),
        expairy_date: new Date(expiry_date).getFullYear() + "-" + (new Date(expiry_date).getMonth() + 1) + "-" + new Date(expiry_date).getDate(),
        package_id: package_id,
        address: address,
        is_active: is_active === 'active',
    }

    const errors = {}

    Object.keys(formData).forEach(fieldName => {
        if (!formData[fieldName]) {
            errors[fieldName] = true
        }
        errors.pan_card = false
        errors.reseller = false
        errors.sales = false
    })

    // setFieldErrors(errors)

    const Spinner = () => (
        <div className="spinner">

            <div className="spinner-inner"></div>
        </div>
    )

    const handleSaveChanges = async () => {
        try {
           
            if (package_id !== "65390f8dffa8b40a9ae9c67b" && !transaction_id) {
                toast.error("Transaction ID is mandatory for paid packages");
                return;
            }
    
            const { data, status } = await instance.post(
                "/Superadmin/Consumer/Package_Subscribe",
                {
                    "start_date": dayjs(start_date).format("YYYY-MM-DD"),
                    "expairy_date": dayjs(expiry_date).format("YYYY-MM-DD"),
                    "package_id": package_id,
                    "transaction_id": transaction_id,
                    "description": description,
                    "domain": domain
                },
                {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
    
            toast.success("Renew success");
            setPopup(false);
            fetchSubscribeData()
            setTriggerRefresh(prev => prev + 1);
        } catch (error) {
            toast.error(error.response.data.message);
            return;
        }
    };
    

    const handleClose = () => {
        setName('')
        setFull_name('')
        setTransaction_id('')
        setDescription('')
      
        setWebsite('')
        setCountry('')
        setRegNumber('')
        setContactEmail('')
        setContactNumber('')
        setEmpIdCode('')
        setPanCard('')
        setReseller('')
        setSales('')
        setLogo('')
        setLogo2('')
        setFinancialStartMonth('April')
        setFinancialEndMonth('March')
        setStartDate('')
        setExpiryDate('')
        setPackageId('')
        setAddress('')
        setPopup(false)
    }

    useEffect(() => {
        fetchCountry()
        fetchPackages()

        if (userdata.role === "Master") {
            fetchResellers()
            fetchSales()
        }
        else {
            userdata.role === "Reseller" && setReseller(userdata._id)
            userdata.role === "Sales" && setSales(userdata._id)
        }
    }, [userdata.role])

    // Auto set the start date and end dates according to package
    useEffect(() => {
        // Basic package
        if (package_id === "65390f8dffa8b40a9ae9c67b") {
            const dayscount = packageList.find(item => item._id === package_id).time_loop_for_every_months

            const expectedstart = data?.expairy_date

            const nextdate = expectedstart-new Date() < 0 ? new Date() : new Date(expectedstart);

            setStartDate(expectedstart-new Date() < 0 ? new Date() : expectedstart)

            console.log(dayscount,package_id)
            nextdate.setDate(nextdate.getDate() + dayscount)
            setExpiryDate(nextdate)
            setDatesAutofilled(true)
        }
    }, [package_id])

    useEffect(() => {
        let timeout

        if (datesAutofilled) {
            timeout = setTimeout(() => {
                setDatesAutofilled(false)
            }, 4000)
        }

        return () => clearTimeout(timeout)
    }, [datesAutofilled])

    useEffect(() => {
        if (row) {
            setSelectedConsumer(row)
            setName(row.original.name)
            setFull_name(row.original.full_name)
          

            setWebsite(row.original.website)
            setCountry(row.original.country)
            setRegNumber(row.original.reg_number)
            setContactEmail(row.original.contact_email)
            setContactNumber(row.original.contact_number)
            setEmpIdCode(row.original.emp_id_code)
            setPanCard(row.original.pan)
            setReseller(row.original.reseller)
            setSales(row.original.sales_person)
            setFinancialStartMonth(months[row.original.financial_start_month - 1])
            setFinancialEndMonth(months[row.original.financial_end_month - 1])
            // setStartDate(new Date(row.original.start_date))
            // setExpiryDate(new Date(row.original.expairy_date))
            setPackageId(row.original.package_id?._id)
            setAddress(row.original.address)
            setIsActive(row.original.is_active ? 'active' : 'inactive')
            setLogo((row.original.logo && row.original.logo.split(',')) || [])
            setLogo2((row.original.logo2 && row.original.logo2.split(',')) || [])
        }
    }, [row])

    // useEffect(() => {
    //     if (selectedConsumer) {
    //         setName(selectedConsumer.original.name)
    //         setFull_name(selectedConsumer.original.full_name)
    //         setDomain(selectedConsumer.original.domain)
    //         setCountry(selectedConsumer.original.country)
    //         setRegNumber(selectedConsumer.original.reg_number)
    //         setContactEmail(selectedConsumer.original.contact_email)
    //         setContactNumber(selectedConsumer.original.contact_number)
    //         setEmpIdCode(selectedConsumer.original.emp_id_code)
    //         setPanCard(selectedConsumer.original.pan)
    //         setReseller(selectedConsumer.original.reseller)
    //         setSales(selectedConsumer.original.sales_person)
    //         setFinancialStartMonth(months[selectedConsumer.original.financial_start_month - 1])
    //         setFinancialEndMonth(months[selectedConsumer.original.financial_end_month - 1])
    //         setStartDate(new Date(selectedConsumer.original.start_date))
    //         setExpiryDate(new Date(selectedConsumer.original.expairy_date))
    //         setPackageId(selectedConsumer.original.package_id._id)
    //         setAddress(selectedConsumer.original.address)
    //         setIsActive(selectedConsumer.original.is_active ? 'active' : 'inactive')
    //         setLogo(selectedConsumer.original.logo?.split(',') || [])
    //         setLogo2(selectedConsumer.original.logo2?.split(',') || [])
    //     }
    // }, [selectedConsumer])

    return (
        <div className="_7wyb">
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={isopen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Renew Subscription
                </DialogTitle>
                <IconButton
                    size="small"
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 14,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent dividers>
                    <div>
                        <div className={`${cl}`}>
                            <div className={`${cl}__card`}>
                                <Grid container spacing={3}>
                                    <Grid item md={6} className="customDatePicker">
                                        <CustomDatePicker
                                            label="Start Date "
                                            value={start_date}
                                            error={fieldErrors.start_date}
                                            onChange={(date) => setStartDate(date.toDate())}
                                        />
                                    </Grid>
                                    <Grid item md={6} className="customDatePicker">
                                        <CustomDatePicker
                                            label="Expiry Date"
                                            value={expiry_date}
                                            error={fieldErrors.expairy_date}
                                            onChange={(date) => setExpiryDate(date.toDate())}
                                        />
                                    </Grid>
                                    {datesAutofilled && <div style={{ fontSize: "12px", color: "green", marginLeft: "24px", marginTop: "4px" }}>Start date and end date are auto-filled according to selected package</div>}
                                    <Grid item md={6}>
                                        <div className="app-form-fields">  
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="package_id"
                                                    label="Package ID"
                                                    variant="outlined"
                                                    select
                                                    value={package_id}
                                                    error={fieldErrors.package_id}
                                                    onChange={(e) => setPackageId(e.target.value)}
                                                >
                                                    {packageList.map((pack) => (
                                                        <MenuItem key={pack._id} value={pack._id}>
                                                            {pack.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="outlined-basic"
                                                    label="TransactionId"
                                                   
                                                    type="primary"
                                                    variant="outlined"
                                                    value={transaction_id}
                                                    onChange={(e) => setTransaction_id(e.target.value)}
                                                    error={fieldErrors.transaction_id}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={12}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    id="outlined-basic"
                                                    label="Description"
                                                    placeholder="Vibho Technologies Pvt Ltd"
                                                    type="primary"
                                                    variant="outlined"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    error={fieldErrors.description}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                 
                                </Grid>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        variant="contained"
                        autoFocus
                        onClick={handleSaveChanges}
                        disabled={loading} // Disable the button while loading
                    >
                        {loading && <Spinner />}
                        <span>{loading ? 'Saving changes...' : 'Save changes'}</span>
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default RenewSubscription
