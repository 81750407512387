import React from 'react'
import { TextField, FormControl } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'


const CustomDatePicker = ({ label, value, onChange, error }) => {
	const today = dayjs()
	
	return (
		<div className="app-form-fields">
			<FormControl fullWidth>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						value={value ? dayjs(value) : undefined}
						slotProps={{
							textField: {
								required: true,
								error: error,
							},
						}
						}
						label={label}
						onChange={onChange}
						renderInput={(params) => <TextField {...params} variant="outlined" />}
					/>
				</LocalizationProvider>
			</FormControl>
		</div>
	)
}

export default CustomDatePicker
