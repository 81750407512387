import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  ListItemIcon,
  TextField,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import {
  BusinessOutlined,
  Cancel,
  Description,
  DescriptionOutlined,
  HelpOutline,
  MailOutline,
  Person,
  PersonOutline,
  PhoneOutlined,
  SubjectOutlined,
  ArrowBack
} from "@mui/icons-material";
import {
  AddCircleOutline,
  Close,
  DeleteOutline,
  Edit,
  Send,
} from "@mui/icons-material";
import AddConsumer from "../components/AddConsumer";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import Header from "../layouts/Header";
import { instance } from "../utils/api";
import { DatePicker } from "@mui/lab";
import { Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import RenewSubscription from "../components/RenewSubscription";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const cl = "app-page-ticket-detail"


function PackageDetails() {
  const [data, setData] = useState([]);
  const [subscribedata, setSubscribeData] = useState([]);
  const Token = localStorage.getItem("Token");
  const { domain } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const fetchConsumerData = async () => {
    try {
      const response = await instance.get(
        `/Superadmin/Consumer/view/${domain}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setData(response.data);

      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchConsumerData();
  }, []);


    const fetchSubscribeData = async () => {
      try {
        const response = await instance.get(
          `/Superadmin/Consumer/Subscribe/list?company_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setSubscribeData(response.data.results);
      } catch (err) {
        console.log(err);
      }
    };
    useEffect(() => {
    fetchSubscribeData();
  }, [id]);

  const Spinner = () => (
    <div className="spinner">
      <div className="spinner-inner"></div>
    </div>
  );
  const formatDateString = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const columns = [
    {
      accessorKey: "company.full_name",
      header: "Company Full Name",
      Cell: ({ row }) => (
        <Tooltip title={`View Author ${row.original.author.name}`}>
          <Link
            to={`/consumerview/${row.original.company.domain}`}
            className="_1hzz"
          >
            <span className="custom-table-cell-text">
              {row.original.company.full_name}
            </span>
          </Link>
        </Tooltip>
      ),
    },
    {
      accessorKey: "pack_info.name",
      header: "Package Name",
      Cell: ({ row }) => (
        <span className="custom-table-cell-text">
          {row.original.pack_info.name}
        </span>
      ),
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }) => (
        <span className="custom-table-cell-text">
          {formatDateString(row.original.start_date)}
        </span>
      ),
    },
    {
      accessorKey: "expairy_date",
      header: "Expiry Date",
      Cell: ({ row }) => (
        <span className="custom-table-cell-text">
          {formatDateString(row.original.expairy_date)}
        </span>
      ),
    },

    {
      accessorKey: "is_active",
      header: "Active",
      Cell: ({ row }) => (
        <span className="custom-table-cell-text">
          {row.original.is_active ? "Yes" : "No"}
        </span>
      ),
    },

    {
      accessorKey: "pack_info.max_employees",
      header: "Max Employees",
      Cell: ({ row }) => (
        <span className="custom-table-cell-text">
          {row.original.pack_info.max_employees}
        </span>
      ),
    },
  ];


  const [editMode, setEditMode] = useState(false);
  const [row, setRow] = useState();
  const [open, setOpen] = useState(false)
 const [renewPopup, setRenewPopup] = useState(false)
 const [triggerRefresh, setTriggerRefresh] = useState(0)

  const handleRenewSubscription = () => {
    setRenewPopup(true);
  };
  const currentPackageDetails = data ? (
    <div className="pack-flex">
      <div>
        <h3 style={{ color: '#FF7A00' }}>Current Package Details: {data.package_id?.name}</h3>
        <p>Start date: {formatDateString(data.start_date)}</p>
        <p>Expiry date: {formatDateString(data.expairy_date)}</p>
      </div>
      <div>
        <button
          style={{
            backgroundColor: 'rgb(255, 122, 0)',
            color: '#ffffff',
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer'
          }}
          onClick={handleRenewSubscription} // Call handleRenewSubscription on click
        >
          Renew Subscription
        </button>
      </div>
    </div>
  ) : null;
  

  return (
    <>
      <div className="agents-list">
        <Header />
      </div>
      <div className="app-page-body">
        <div className="app-page-wrapper">
        
        
        <div className={`${cl}__title`}>
                <Tooltip title="back">
                    <Link to="/consumers">
                        <IconButton size="small">
                            <ArrowBack />
                        </IconButton>
                    </Link>
                </Tooltip>
                <h1>Package details list</h1>
               
            </div>
        
        <div className="package-details-container">
  {currentPackageDetails}
</div>

       
          <div className="app-table-container">
            <div className="app-table-component">
              {subscribedata ? (
                <MaterialReactTable
                  columns={columns}
                  data={subscribedata}
                 
                  positionActionsColumn="last"
                />
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </div>
      <RenewSubscription  domain={domain} isopen={renewPopup} setPopup={setRenewPopup} row={row} setTriggerRefresh={setTriggerRefresh} data={data} fetchSubscribeData={fetchSubscribeData} />
    </>
  );
}

export default PackageDetails;
