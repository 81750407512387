import { useEffect, useState } from "react"
import { MaterialReactTable } from 'material-react-table'
import { Box, Button, ListItemIcon } from "@mui/material"
import { MenuItem } from '@mui/material'
import { AddCircleOutline, DeleteOutline, Edit } from "@mui/icons-material"
import AddConsumer from "../components/AddConsumer"
import Header from "../layouts/Header"
import { instance } from "../utils/api"
import { Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css'
import { FaCreditCard } from "react-icons/fa"
import RenewSubscription from "../components/RenewSubscription"
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
const cl = 'app-page-ticketlist'

function ConsumersList() {
	const [apiData, setApiData] = useState([])
	const [triggerRefresh, setTriggerRefresh] = useState(0)
	const [isLoading, setIsLoading] = useState(true)
	// const [loading, setLoading] = useState(false)
	// const [selectedConsumer, setSelectedConsumer] = useState(null)
	const [editMode, setEditMode] = useState(false)
	const [row, setRow] = useState(undefined)
	const [open, setOpen] = useState(false)
	const [renewPopup, setRenewPopup] = useState(false)

	const Token = localStorage.getItem("Token")

	const fetchData = async () => {
		try {
			const response = await instance.get("/Superadmin/Consumer/list", {
				headers: {
					Authorization: `Bearer ${Token}`,
					'Content-Type': 'application/json',
				}
			})
			const activeConsumers = response.data.results.filter(consumer => consumer.is_active)


			const resultsWithSerialNumber = activeConsumers.map((result, index) => ({
				...result,
				serialNumber: index + 1,
			}))

			setApiData({ results: resultsWithSerialNumber })
			setApiData({ results: resultsWithSerialNumber })
			// console.log(resultsWithSerialNumber)
			// console.log(apiData.results)
			// console.log(response.data)
		} catch (error) {
			console.error("Error:", error)
		}
		finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [triggerRefresh])

	const Spinner = () => (
		<div className="spinner">

			<div className="spinner-inner"></div>
		</div>
	)

	const columns = [
		// {
		//   accessorKey: "serialNumber",
		//   header: "S.No",
		//   Cell: ({ renderedCellValue, row }) => (
		//     <Tooltip title={`View Consumer ${renderedCellValue}`}>
		//       <Link to={`/consumerview/${row.original.domain}`} className="_1hzz">
		//         <span className="custom-table-cell-text plus">
		//           {/* <span className="plus-sign">+</span> */}
		//           {renderedCellValue}
		//         </span>
		//       </Link>
		//     </Tooltip>
		//   ),
		// },


		{
			accessorKey: "name",
			header: "Name",
			Cell: ({ renderedCellValue, row }) => (
				<Tooltip title={`View Consumer ${renderedCellValue}`}>
					<Link to={`/consumerview/${row.original.domain}`} className="_1hzz">
						<span className="custom-table-cell-text plus">
							{/* <span className="plus-sign">+</span> */}
							{renderedCellValue}
						</span>
					</Link>
				</Tooltip>
			),
		},
		{
			accessorKey: "full_name",
			header: "FullName",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorFn: (row) => `${row.ActiveEmployees+row.InActiveEmployees}`,
			id:'employees',
			header: "Employees",
			Cell: ({ renderedCellValue, row}) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "contact_email",
			header: "Email",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "contact_number",
			header: "Contact",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "domain",
			header: "Domain",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "website",
			header: "Website",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "country_id.name",
			header: "Country",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "emp_id_code",
			header: "Employee ID",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorFn: (row) => row.reseller ? row.reseller?.name : row.sales_person ? row.sales_person?.name : 'Admin',
			header: "Added By",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "pan",
			header: "Pan Card",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},


	]

	const months = [
		'January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December',
	]

	const updatedColumns = [...columns]
	const actionsColumn = updatedColumns.find((col) => col.accessorKey === "actions")

	if (actionsColumn) {
		updatedColumns.splice(updatedColumns.indexOf(actionsColumn), 1)
		updatedColumns.push(actionsColumn)
	}

	console.log(apiData);
  const exportCsv = async (rows, exportAll) => {
    const rowData = exportAll ? apiData.results : rows.map(row => {
      const newRow = { ...row.original };
      for (const key in newRow) {
        if (typeof newRow[key] === 'object' && newRow[key] !== null) {
          Object.entries(newRow[key]).forEach(([subKey, value]) => {
            newRow[`${key}_${subKey}`] = value;
          });
          delete newRow[key];
        }
      }
      return newRow;
    });
  
    if (rowData.length === 0) {
      console.error('No data to export.');
      return;
    }
  
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      decimalSeparator: '.',
      useKeysAsHeaders: true,
    });
  
    const csv = generateCsv(csvConfig)(rowData);
    await download(csvConfig)(csv);
  };
  
  

	return (
		<>
			<AddConsumer isopen={open} setPopup={() => { setOpen(false); setEditMode(false) }} editMode={editMode} row={row} setTriggerRefresh={setTriggerRefresh} />
			<RenewSubscription isopen={renewPopup} setPopup={setRenewPopup} row={row} setTriggerRefresh={setTriggerRefresh} />
			<div className="agents-list">
				<Header />
			</div>
			<div className='app-page-body'>
				<div className='app-page-wrapper'>
					<div className={`${cl}`}>
						<div className={`${cl}__header`}>
							<h2 className={`${cl}__header--title`}>Consumers List</h2>
							<ul className={`${cl}__header--actions`}>
								<li className={`${cl}__header--actionsitem`}>
									<Button variant="contained" onClick={() => { setOpen(true); setEditMode(false) }}><AddCircleOutline /> Add Consumer</Button>
								</li>
							</ul>
						</div>
					</div>
					<div className="app-table-container">
						<div className="app-table-component">
							{isLoading ? (
								<Spinner />
							) : (
								<MaterialReactTable columns={updatedColumns} data={apiData.results || []} 
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '16px',
                      padding: '8px',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Button
                      disabled={table.getPrePaginationRowModel().rows.length === 0}
                      onClick={() => exportCsv(table.getPrePaginationRowModel().rows, false)}
                      startIcon={<FileDownloadIcon />}
                      variant="outlined"
                    >
                      Export All Rows (Excel)
                    </Button>
                   
                  
                  </Box>
                )}
                enableRowActions
									renderRowActionMenuItems={({ closeMenu, row }) => [
										<MenuItem
											key={0}
											onClick={() => {
												setRow(row)
												setEditMode(true)
												setOpen(true)
												closeMenu()
											}}
											sx={{ m: 0 }}
										>
											<ListItemIcon>
												<Edit />
											</ListItemIcon>
											Edit
										</MenuItem>,
                    	<Link to={`/packageview/${row.original._id}/${row.original.domain}`}>
										<MenuItem
											key={0}
											
											sx={{ m: 0 }}
										>
                      
											<ListItemIcon>
												<FaCreditCard />
											</ListItemIcon>
											View Subscription
										</MenuItem>
                    </Link>,
										
									]}
									positionActionsColumn="last"
								/>

							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default ConsumersList
