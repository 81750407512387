import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Apartment, ArrowBack, Description, Download, Email, Person, Phone, Replay, Reply, Send } from "@mui/icons-material";
import Header from "../layouts/Header";
import { Link, useParams } from "react-router-dom";
import { instance } from "../utils/api";

const cl = "app-page-ticket-detail"
function ConsumerDetails() {
    const [data, setData] = useState([]);
    const [subscribedata, setSubscribeData] = useState([]);
    const Token = localStorage.getItem("Token")
    const { domain } = useParams();
const{id}=useParams();
    const fetchConsumerData = async () => {
        try {
            const response = await instance.get(`/Superadmin/Consumer/view/${domain}`, {
                headers: {
                  Authorization: `Bearer ${Token}`,
                  'Content-Type': 'application/json',
                }
              });
            setData(response.data);

            console.log(response.data)
        } catch (err) {
            
            console.log(err);
        }
    };

    useEffect(() => {

        fetchConsumerData()
    }, [])

    const formatDateString = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

   



    return (
        <>
            <div className="ticketlist">
                <Header />
            </div>
            <div className={cl}>
                <div className={`${cl}__title`}>
                    <Tooltip title="back">
                        <Link to="/consumers">
                            <IconButton size="small">
                                <ArrowBack />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <h1>Consumer Detail</h1>
                </div>
                <div className={`${cl}__body`}>
                    <Grid container spacing={3}>
                        <Grid item md="10">
                            <div className={`${cl}__ticketcard`}>

                                <div className={`${cl}__ticketcard__body`}>
                                    <Grid spacing={3} container>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Consumer Name</h6>
                                                <p>{data.name}</p>
                                            </div>
                                        </Grid>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Consumer code</h6>
                                                <p>{data.emp_id_code}</p>
                                            </div>
                                        </Grid>

                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Domain</h6>
                                                <p>{data.domain}</p>
                                            </div>
                                        </Grid>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Registration number</h6>
                                                <p>{data.reg_number}</p>
                                            </div>
                                        </Grid>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Active Employees</h6>
                                                <p>{data.ActiveEmployees}</p>
                                            </div>
                                        </Grid>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>In Active Employees</h6>
                                                <p>{data.InActiveEmployees}</p>
                                            </div>
                                        </Grid>

                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Contact email</h6>
                                                <p>{data.contact_email}</p>
                                            </div>
                                        </Grid>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Contact number</h6>
                                                <p>{data.contact_number}</p>
                                            </div>
                                        </Grid>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Country</h6>
                                                <p>{data.country}</p>
                                            </div>
                                        </Grid>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Address</h6>
                                                <p>{data.address}</p>
                                            </div>
                                        </Grid>

                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Financial Start Month</h6>
                                                <p>{new Date(0, data.financial_start_month - 1).toLocaleString('default', { month: 'long' })}</p>
                                            </div>
                                        </Grid>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Financial End Month</h6>
                                                <p>{new Date(0, data.financial_end_month - 1).toLocaleString('default', { month: 'long' })}</p>
                                            </div>
                                        </Grid>

                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>Start Date</h6>
                                                <p>{formatDateString(data.start_date)}</p>
                                            </div>
                                        </Grid>
                                        <Grid item md="6">
                                            <div className={`${cl}__ticketinfo`}>
                                                <h6>End Date</h6>
                                                <p>{formatDateString(data.expairy_date)}</p>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </div>
                            </div>

                        </Grid>
                       
                    </Grid>
                </div>
            </div>
        </>
    )
}
export default ConsumerDetails;
